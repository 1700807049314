import  React from 'react';
import Layout from "../components/Layout"
import Contact from "../components/Contact"


const contactPage = () => {
  return (
      <Layout>
        <Contact/>
      </Layout>
    )
}

export default contactPage;